import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { sanitizeData } from "../utils/Common";
import { ObjectType } from "../types/tableTypes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StrippedTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFCFF",
  },
}));

const FilledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "6px",
  background: "#F5F8FF",
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
}));

interface LeverageTableProps {
  tableHeader: any[];
  tableBody: ObjectType;
  label: string;
  tableClassName?: string;
}

const LeverageTable: React.FC<LeverageTableProps> = ({
  tableHeader,
  tableBody,
  label,
  tableClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getResultText = (key: string, parentKey: ObjectType, idx: number) => {
    let className = "";

    if (`${key}`.includes("-") && (!isNaN(+key) || `${key}`.includes("%"))) {
      className = "pass-text";
    } else if (`${key}`.includes("+") && (!isNaN(+key) || `${key}`.includes("%"))) {
      className = "failed-texts";
    }

    const showColouredTexts =
      (parentKey?.data?.length === 3 && parentKey?.data[1] === key) ||
      parentKey?.label === "requested";

    let keyToBeShown = key;
    if (Boolean(keyToBeShown) && /[+-]/.test(keyToBeShown)) {
      if (parentKey?.label?.toLowerCase() === "requested") {
        keyToBeShown = keyToBeShown.replace(/[+-]/g, "");
      }
    }

    let displayValue;
    if (`${keyToBeShown}`.includes("%")) {
      displayValue = keyToBeShown;
    } else if (typeof keyToBeShown === "string" && idx !== 0) {
      displayValue = sanitizeData(t(`${keyToBeShown}`), true, 2);
    } else {
      displayValue = sanitizeData(t(keyToBeShown), true);
    }

    return (
      <span className={showColouredTexts ? className : ""}>
        {displayValue}
      </span>
    );
  };

  const renderTableBody = useMemo(() => {
    if (!tableBody) return null;

    if (!Array.isArray(tableBody[0])) {
      return Object.keys(tableBody).map((key, idx) => (
        <StrippedTableRow key={key}>
          {tableBody[key]?.data?.map((data: any, index: number) => (
            <StyledTableCell
              style={{
                color: "black",
                borderBottom: "none",
                marginBottom: "8px",
                borderRight: "1px solid #D5DCED",
              }}
              key={data}
              className={index === 0 ? tableBody[key].borderColor : ""}
            >
              {getResultText(data, tableBody[key], index)}
            </StyledTableCell>
          ))}
        </StrippedTableRow>
      ));
    } else {
      return tableBody.map((body: any, index: number) => (
        <React.Fragment key={index}>
          <FilledTableRow
            style={
              body[0] === "Final Price Adjustment"
                ? { borderRadius: "6px", background: "#E4EAFF" }
                : {}
            }
          >
            {body.map((data: any, idx: number) => (
              <StyledTableCell
                style={
                  idx === 0 || body.length === 3
                    ? {
                        color: "black",
                        borderBottom: "none",
                        marginBottom: "8px",
                      }
                    : {
                        color: "black",
                        borderBottom: "none",
                        marginBottom: "8px",
                        display: "flex",
                        justifyContent: "end",
                      }
                }
                key={data}
                sx={{ borderBottom: "none" }}
              >
                <span
                  style={
                    idx !== 0
                      ? {
                          color: "var(--primary-primary-main-700, #40128B)",
                        }
                      : {}
                  }
                  className={
                    data &&
                    idx !== 0 &&
                    typeof data === "string" &&
                    data.includes("-") &&
                    label !== "pricing"
                      ? "red-color"
                      : ""
                  }
                >
                  {index === 0 && body[0] === "Final Price Adjustment" ? (
                    <b>{t(data)}</b>
                  ) : (
                    t(data)
                  )}
                </span>
              </StyledTableCell>
            ))}
          </FilledTableRow>
        </React.Fragment>
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableBody, t, label]);

  return (
    <Paper className={classes.paper}>
      <TableContainer
        sx={{
          padding: "0px 0px",
          borderRadius: "8px",
          fontSize: "1.1rem",
        }}
      >
        <Table className={tableClassName}>
          {tableHeader?.length ? (
            <TableHead
              style={{
                backgroundColor: "#9E9C9C1A",
              }}
            >
              <StrippedTableRow style={{ borderRadius: "8px" }}>
                {tableHeader.map((ele, idx) => (
                  <StyledTableCell
                    className={ele?.headerClassName ?? ""}
                    key={ele?.label}
                  >
                    {t(ele?.label ?? ele)}
                  </StyledTableCell>
                ))}
              </StrippedTableRow>
            </TableHead>
          ) : null}
          <TableBody>{renderTableBody}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default React.memo(LeverageTable);