/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import jwt_decode from "jwt-decode";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/BorderColor';

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { TermSheetV2, DownloadTermSheet } from "@toorak/op-web-common-service/src/index";
// import DownloadTermSheet from "./v2/downloadTermSheet";
// import TermSheetV2 from "./v2/v2/termSheet";

import CloseIcon from "../../assets/Close-Icon.svg";

import { capitalizeFirstLetterOfEachWord, getCookie, sanitizeCurrency } from "../../utils/Common";
import { DSCRDefaultFeeDetailsDefault, BridgeFeeDetails, normalizeFeeDetailsConfig, convertStringToSnakeCase } from "../../utils/Constants";
import { LoansContext } from "../../context/LoansContext";
import { RootContext } from "../../context/RootContext";

import { getTermSheetData, getSuggestedLoanTerms } from "./Common";

import TermSheetMenu from "./TermSheetMenu";

import { generateNewTermSheet, getTermSheetVersions, getTermSheetBasedOnVersion, sendTermSheetEmail, addNewFieldConfig, updateFieldConfigBasedOnOriginator, getFieldConfigBasedOnOriginator, updateLatestTermSheetData } from "../../services/termSheet";
import { sanitizeLoanDataForTermSheet } from "../../utils/Common";

import { useStyles } from "./termSheet.style";
import { ObjectType } from "../../types/tableTypes.js";
import SnackBar from "../snackBar/snackBar";
import DisclaimerPopupWithButtons from "../common/DisclaimerPopupWithButtons";

type ParamType = {
  loanType: string | undefined;
  loanId: string | undefined;
  propType: string | undefined;
};

const DefaultSignature = (props: any) => {
  const { email } = props;
  return (<>
    <span>
      <Typography>Merchants Mortage & Trust Corporation</Typography>
    </span>
    <span>
      <Typography>7400 East Crestline Circle#250</Typography>
    </span>
    <span>
      <Typography>Greenwood Village, CO 80111</Typography>
    </span>
    <span>
      <Typography
        sx={{ color: "#1976d2", textDecoration: "underline" }}
      >
        {email}
      </Typography>
    </span>
  </>)
};

export default function TermSheetForm(props: any) {
  let originatorData: any =
    getCookie("originatorData") || localStorage.getItem("originatorData") || "{}";
  const orgId = getCookie("orgId");

  originatorData = JSON.parse(originatorData);

  const accessToken: string = getCookie("sizer_access_token") || "";
  const decodedData: ObjectType = jwt_decode(accessToken);

  const emailBodyStr2 = useMemo(()=>{
    return `
    <p>
      Dear (--- please enter name here ---),
    </p>
    <p>
      Thank you for the opportunity to provide financing on this property.  ${originatorData?.accountName} would consider structuring a loan subject to the following outline.
    </p>
    `
  },[originatorData]);

  const { evaluationID = "" } = props;
  const [termSheetAnchorEl, setTermSheetAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenDownloadTermSheet, setIsOpenDownloadTermSheet] = useState(false);
  const [openTermSheetMenu, setOpenTermSheetMenu] = useState<boolean>(false);
  const [defaultFeeDetails, setDefaultFeeDetails] = useState<any>([]);
  const [orgLevelFees, setOrgLevelFees] = useState<any>([]);
  const [orgLevelRequirements, setOrgLevelRequirements] = useState<any>([]);
  const [emailContent, setEmailContent] = useState(emailBodyStr2);
  const [triggeredSendEmail, setTriggeredSendEmail] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const loanContextData = useContext(LoansContext);
  const [latestTermSheetVersion, setLatestTermSheetVersion] = useState<number | string>("");
  const [isFooterEditEnabled, setIsFooterEditEnabled] = useState<boolean>(false);
  const [isEditsEnable, setIsEditsEnable] = useState<object>({ isSubjectEditable: true, isFooterEditable: true });
  const [signatureData, setSignatureData] = useState<any>({});
  const [isEmailSentOfCurrentVersion, setIsEmailSentOfCurrentVersion] = useState<boolean>(false)
  const [isTermsheetAlreadySaved, setIsTermsheetAlreadySaved] = useState<boolean>(false)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)
  const [isMultiProperty, setIsMultiProperty] = useState<boolean>(false)

  const permissionsToRenderInUI = {
    showLoanDetails: true,
    showFeeDetails: true,
    showEmailSection: true,
    showEmailBody: true,
    isFullView: true,
    showMerchantsMortgage: false,
    showRequirements: true,
    showFooterActions: true,
    showDefaultSignature: false
  }
  const {
    dynamicConfig,
    termSheetData,
    setTermSheetData,
    setTermSheetEmailData,
    setTermSheetLoanData,
    setTermSheetSuggestedLoanData,
    setTermSheetFeeData,
    setTermSheetRequirementsData,
    termSheetConfig,
    setTermSheetConfig,
    termSheetVersions,
    setTermSheetVersions,
    termSheetAction,
    showTermSheet,
    setTermSheetAction,
    currentTermSheetVersion,
    setCurrentTermSheetVersion,
    isOpenTermSheet,
    setIsOpenTermSheet
  } = useContext(RootContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const params = useParams<ParamType>();

  const { loanType, loanId } = params;

  useEffect(() => {
    if(loanContextData?.propertyDetails?.length) {
      if(loanContextData?.propertyDetails?.length > 1) {
        setIsMultiProperty(true)
      } else {
        setIsMultiProperty(false)
      }
    }

  },[loanContextData?.propertyDetails])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setTermSheetAnchorEl(event.currentTarget);
    setOpenTermSheetMenu(true);
    setTermSheetAction("")
    setIsOpenTermSheet(false)
    setIsOpenDownloadTermSheet(false)
  };

  const updateTermSheetFormData = (key: string, value: any) => {
    if (["emails", "emailBody", "feeDetails", "requirements"].includes(key)) {
      setTermSheetData((state: any) => ({ ...state, [key]: value }));
    }
  };

  const handleClose = () => {
    setTermSheetAnchorEl(null);
    setOpenTermSheetMenu(false);
    setIsOpenTermSheet(false);
    setTermSheetAction("");
    setIsTermsheetAlreadySaved(false)
    setIsOpenDownloadTermSheet(false);
    getTermSheetVersion();
  };

  const handleFeeChange = useCallback((e: any, key: string, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...(JSON.parse(JSON.stringify(feeDetailsData))),
      [key]: e.target.value || ""
    }
    updateTermSheetFormData("feeDetails", tempFeeDetails);
    setTermSheetFeeData(tempFeeDetails);
  }, []);

  const handleFeeBlur = useCallback((e: any, key: string, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...(JSON.parse(JSON.stringify(feeDetailsData))),
      [key]: `${Number(e).toFixed(2)}`
    }
    updateTermSheetFormData("feeDetails", tempFeeDetails);
    setTermSheetFeeData(tempFeeDetails);
  }, []);

  const handleSignatureSave = (data: any) => {
    setSignatureData(data)
  };

  const handleFooterChange = (e: any, key: string, termSheetData: any) => {
    switch (key) {
      case "name":
        break;
      case "email":
        break;
      case "accountName":
        break;
    }
  }
  // add new custom fee
  const handleAddNewFeeConfig = async (addedFees: any, newFees: any, newlyAddedFees: any, visibilityChangedFields: any) => {
    const headers = {
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };

    const loanTypeToBePassed = loanType === "InvestorDSCR" ? "30 Year Loan" : "Bridge Loan";
    const termSheetDynamicFields: any = [];
    if (newlyAddedFees?.length) {
      newlyAddedFees?.forEach((fee: any) => {
        termSheetDynamicFields.push({
          op: "add",
          field: convertStringToSnakeCase(fee),
          loanType: loanTypeToBePassed,
          isActive: true
        })
      });
      const payload = {
        // partyId: getCookie("partyId"),
        sellers: [],
        loanTypes: [],
        termSheetDynamicFields
      }


      await Promise.all(newlyAddedFees.map(async (fee: any) => {
        addNewFieldConfig({ field: convertStringToSnakeCase(fee), dataType: "currency", loanType: loanTypeToBePassed }, headers).then((res: any) => {
          updateFieldConfigBasedOnOriginator(orgId, payload, headers).then((orgRes: any) => {
            return Promise.resolve(orgRes);
          }).catch((err: any) => {
            return Promise.reject(err);
          })
        })
      }));
    }

    // changes for visibility
    const updatedFees = {...newFees}
    if (visibilityChangedFields?.length) {
      const toBeUpdatedFields: any = [];
      visibilityChangedFields.forEach((field: any) => {
        if(!field.isVisible()) {
          delete updatedFees[field?.label]
        }
        toBeUpdatedFields.push({
          op: "update",
          field: field?.id,
          loanType: loanTypeToBePassed,
          isActive: false
        })
      });
      await updateFieldConfigBasedOnOriginator(orgId, {
        // partyId: getCookie("partyId"),
        sellers: [],
        loanTypes: [],
        termSheetDynamicFields: toBeUpdatedFields
      }, headers);
    }

    setDefaultFeeDetails(addedFees?.filter((field: any) => field?.isVisible()));
    setOrgLevelFees(addedFees);
    updateTermSheetFormData("feeDetails", updatedFees);
  };

  // delete fee
  const handleDeleteFee = useCallback(async (key: any, feeDetailsData: any) => {
    try {
      const headers = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const loanTypeToBePassed = loanType === "InvestorDSCR" ? "30 Year Loan" : "Bridge Loan";

      const newFeeDetails: any = { ...(JSON.parse(JSON.stringify(feeDetailsData))), };
      delete newFeeDetails[key];
      const toBeUpdatedFields: any = [{
        op: "update",
        field: key,
        loanType: loanTypeToBePassed,
        isActive: false
      }];
      await updateFieldConfigBasedOnOriginator(orgId, {
        // partyId: getCookie("partyId"),
        sellers: [],
        loanTypes: [],
        termSheetDynamicFields: toBeUpdatedFields
      }, headers);
      updateTermSheetFormData("feeDetails", newFeeDetails)
      setTermSheetFeeData({ ...newFeeDetails });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getLoanData = (loanData: any) => {
    const returnData: any = {};
    Object.keys(loanData).forEach((key) => {
      const value = (typeof loanData[key] === 'string' && (loanData[key]?.includes(',') || loanData[key]?.includes('$'))) ? loanData[key]?.replace(/[$,]/g, '') : loanData[key];
      returnData[key] = value;
    });
    return returnData;
  };

  const getGenarateTermSheetPayload = (loanData: any, defaultFees: any, tempTermSheetData: any, suggestedTerms: any) => {
    const termSheetLoanDataConfig = [
      ...loanData[0],
      ...loanData[1],
      ...(suggestedTerms?.length > 0 ? suggestedTerms[0] : []),
      ...(suggestedTerms?.length > 1 ? suggestedTerms[1] : [])
    ];

    const termSheetInfo: any = {};
    termSheetLoanDataConfig?.map((sheetConfig) => {
      if (sheetConfig.key !== "--") {
        termSheetInfo[sheetConfig.key] = sheetConfig.value
      } else if (sheetConfig.value === "--") {
        termSheetInfo[sheetConfig.key] = null;
      }
    });

    const payload = {
      termSheetLoanInfo: {
        ...getLoanData(termSheetInfo)
      },
        signatureInfo: {
          ...signatureData
        },
      feeDetails: defaultFees,
      requirements: tempTermSheetData?.requirements?.filter((r: string) => r !== ""),
      emailSentOn: loanData.emailSentOn,
      propertyAddressDetails : tempTermSheetData?.propertyAddressDetails,
      email: {
        from: termSheetConfig?.email?.fromEmail,
        to: tempTermSheetData?.emails?.toEmails?.length ? tempTermSheetData?.emails?.toEmails : [],
        cc: tempTermSheetData?.emails?.ccEmails?.length ? tempTermSheetData?.emails?.ccEmails : [],
        subject: tempTermSheetData?.emails?.subject ?? termSheetConfig?.email?.subject,
        content: tempTermSheetData?.emailBody ?? emailBodyStr2,
        date: moment(termSheetConfig?.email?.date, "MM/DD/YYYY").format("YYYY-MM-DD") ?? moment().format("YYYY-MM-DD"),
      }
    };
    return {...payload}
  }

  // create-update  - api
  const generateOrUpdateTermSheet = useCallback(async (loanData: any, defaultFees: any, tempTermSheetData: any, suggestedTerms: any, mode: string) => {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const payload = getGenarateTermSheetPayload(loanData, defaultFees, tempTermSheetData, suggestedTerms);
      if(mode === 'create') {
        const { data } = await generateNewTermSheet(payload, loanId, realmHeaders);
        setCurrentTermSheetVersion(data?.data?.termSheetVersion);
      } else {
        const version = latestTermSheetVersion;
        await  updateLatestTermSheetData(payload, loanId, realmHeaders, version )
      }
      
      setIsOpenTermSheet(true);
      setOpenTermSheetMenu(false);
      // return data?.data?.termSheetVersion
    } catch (err) {
      console.error(err);
    }
  }, [latestTermSheetVersion, signatureData]);


  // get all term sheet versions
  const getTermSheetVersion = async () => {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const termSheetVersions = await getTermSheetVersions(realmHeaders, loanId);
      const sortedTermSheetVersions = termSheetVersions?.data?.data.sort((a: any, b: any) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setLatestTermSheetVersion(sortedTermSheetVersions[0]?.termSheetVersion)
      setTermSheetVersions(sortedTermSheetVersions)
      if(sortedTermSheetVersions?.length) {
        fetchLatestTermSheetData(sortedTermSheetVersions[0]?.termSheetVersion)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const fetchLatestTermSheetData = async (version: string) => {
    const loanTypeStr = loanContextData?.loanDetails?.productType || loanContextData?.fetchData?.productType;
    const response: any = await fetchGetTermSheetBasedOnVersion(version);
        const data = getTermSheetData(loanContextData?.loanDetails?.productType, loanContextData, t, termSheetAction, response, loanContextData?.defaultSelectedDataView);
        if(response?.emailSentOn) {
          setIsEmailSentOfCurrentVersion(true)
          setIsTermsheetAlreadySaved(false)
        } else {
          setIsEmailSentOfCurrentVersion(false)
          setIsTermsheetAlreadySaved(true)
        }
        const newSuggestedLoanTerms = getSuggestedLoanTerms(loanTypeStr, loanContextData, t, termSheetAction, response);
        const newRequirements = response?.requirements || [""];
        const newFeeDetails = response?.feeDetails || (JSON.parse(JSON.stringify(termSheetData?.feeDetails)));
        const ccEmails = response?.email?.cc;
        const toEmails = response?.email?.to;
        const emailSentOn = response?.emailSentOn;
        const emailBody = response?.email?.content;
        const subject = response?.email?.subject
        setTermSheetData({
          emailSentOn,
          loanData: data,
          suggestedLoanTerms: newSuggestedLoanTerms,
          requirements: newRequirements || [""],
          feeDetails: newFeeDetails,
          emailBody: emailBody ?? emailBodyStr2,
          emails: {
            fromEmail: "noreply@bplsizer.com",
            toEmails,
            ccEmails,
            subject
          },
          currentTermSheetVersion: version
        });
  }

  const getEmailPayload = (tempTermSheetData: any) => {
    const loanDataFields: any = [];
      const suggestedDataFields: any = [];

      tempTermSheetData?.loanData?.map((loanInfo: any) => {
        loanInfo?.map((info: any) => {
          loanDataFields.push({
            label: info?.label,
            value: info?.value
          })

        });
      });

      tempTermSheetData?.suggestedLoanTerms?.map((loanInfo: any) => {
        loanInfo?.map((info: any) => {
          suggestedDataFields.push({
            label: info?.label,
            value: info?.value
          })
        });
      });

      const payload: any = {
        templateData: {
          subject: tempTermSheetData?.emails?.subject ?? termSheetConfig?.email?.subject,
          originatorLogo: "",
          emailBody: tempTermSheetData?.emailBody ?? emailBodyStr2,
          loanType: loanType === "InvestorDSCR" ? "dscr" : "bridge",
          totalPoints: loanContextData?.loanDetails?.loanEconomics?.totalOrgAndDiscountPoints ?? null,
          loanDetails: {
            dynamicFields: loanDataFields
          },
          suggestedLoanTerms: {
            dynamicFields: suggestedDataFields
          },
          propertyAddressDetails : tempTermSheetData?.propertyAddressDetails,
          feeDetails: {
            dynamicFields: Object.keys(tempTermSheetData?.feeDetails)?.map((fee: any) => {
              return {
                label: fee,
                value: sanitizeCurrency(tempTermSheetData?.feeDetails?.[fee])
              }
            })
          },
          requirements: tempTermSheetData?.requirements?.filter((r: string) => r !== "") || [],
          viewLoan: window.location.href,
          originator: {
            fullName: signatureData?.name,
            email: signatureData?.email,
            originatorName: signatureData?.accountName,
            phone: "",
          }
        },
        sendTo: tempTermSheetData?.emails?.toEmails?.length ? tempTermSheetData?.emails?.toEmails : [],
        sendCc: tempTermSheetData?.emails?.ccEmails?.length ? tempTermSheetData?.emails?.ccEmails : [],
        sender: termSheetConfig?.email?.fromEmail
      }
      return {...payload}
  }

  // email notification api
  const sendEmail = useCallback( async (tempTermSheetData: any, isTermsheetAlreadySaved: boolean) => {
      if (tempTermSheetData?.emails?.toEmails?.length === 0) {
        setTriggeredSendEmail(true);
        return;
      }
  
      try {
        const headers = {
          "kc-realm": dynamicConfig?.ViteAppKcRealm,
        };
        const payload = getEmailPayload(tempTermSheetData)
        await sendTermSheetEmail(payload, headers, dynamicConfig)
        setIsEmailSentOfCurrentVersion(true)
        if (["generate", "regenerate", "edit"]?.includes(termSheetAction)) {
          const mode = (termSheetAction === "edit" || isTermsheetAlreadySaved) ? "update" : "create";
          await saveOrUpdateNewTermSheet(tempTermSheetData, mode, true)
        } else {
          setIsOpenTermSheet(false);
          const message = termSheetAction === 'generate' ?
            "Term Sheet Generated And Saved Succesfully." : termSheetAction === 'regenerate' ?
            "Term Sheet Regenerated And Saved Succesfully." : termSheetAction === 'edit' ?
            "Email Sent And Saved Succesfully." : ""
          setSnackBarMsg(message)
          getTermSheetVersion();
          setTermSheetAction("");
          setIsTermsheetAlreadySaved(false)
        }
      } catch (err) {
        console.error(err);
      }
    }

  , [termSheetAction, isTermsheetAlreadySaved, signatureData]);

  const saveOrUpdateNewTermSheet = async (tempTermSheetData: any, mode: string, isEmailSent: boolean) => {
    try {
      const loanTypeStr = loanContextData?.loanDetails?.productType || loanContextData?.fetchData?.productType;
        const data: any = getTermSheetData(loanTypeStr, loanContextData, t, termSheetAction, tempTermSheetData, loanContextData?.defaultSelectedDataView);
        const suggestedTerms = getSuggestedLoanTerms(loanTypeStr, loanContextData, t, termSheetAction, tempTermSheetData);
        const feeDetailsObj: any = tempTermSheetData?.feeDetails;
        if(isEmailSent) {
          data.emailSentOn =  moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        await generateOrUpdateTermSheet(data, feeDetailsObj, tempTermSheetData, suggestedTerms, mode);
        if(isEmailSent) {
          setIsOpenTermSheet(false);
          setTermSheetAction("");
          setIsTermsheetAlreadySaved(false)
        }
        const message = mode === 'update' ? "Term Sheet Updated And Saved Succesfully." 
          : termSheetAction === 'generate'  ? "Term Sheet Generated And Saved Succesfully." 
          : termSheetAction === 'regenerate' ? "Term Sheet Regenerated And Saved Succesfully." : ""
        setSnackBarMsg(message)
        getTermSheetVersion();
    } catch (error) {
      console.error(error)
      setIsOpenTermSheet(false);
      setTermSheetAction("");
    }
  }

  //save TermSheet button handler
  const saveTermSheetHandler = async (tempTermSheetData: any, isTermsheetAlreadySaved: boolean) => {
    if (tempTermSheetData?.emails?.toEmails?.length === 0) {
      setTriggeredSendEmail(true);
      return;
    }

    let mode: string = 'create'
    if(termSheetAction === "edit") {
      mode = 'update'
    }
    if((termSheetAction === 'generate' || termSheetAction === 'regenerate') && isTermsheetAlreadySaved) {
      mode = 'update'
    }
    saveOrUpdateNewTermSheet(tempTermSheetData, mode, false)
    setIsTermsheetAlreadySaved(true)
  }

  // on change of emails
  const handleEmailChange = (toEmails: any, ccEmails: any, tempTermSheetData: any) => {
    const emails = {
      ...tempTermSheetData.emails,
      toEmails: [...toEmails],
      ccEmails: [...ccEmails]
    }
    updateTermSheetFormData("emails", emails);

    // setEmailData(emails);
    // setTermSheetData({ ...tempTermSheetData, emails: emails })
    // setTermSheetEmailData(emails)
  };

  // termsheet payload for create api with default values
  const getTermSheetDataForSave = (tsData: any) => {
    let termSheetLoanDataConfig: any = [];

    if (tsData?.loanData?.length) {
      termSheetLoanDataConfig = [...(tsData?.loanData[0] ?? []),
      ...(tsData?.loanData[1] ?? [])
      ]
    }

    if (tsData?.emailBody) {
      setEmailContent(tsData?.emailBody)
    }

    const termSheetInfo: any = {};
    termSheetLoanDataConfig?.map((sheetConfig: any) => {
      if (sheetConfig.key !== "--") {
        // if(['Zip Code', 'Credit Score'].includes(sheetConfig.label)) {
        //   termSheetInfo[sheetConfig.key] = sheetConfig.value
        // } else {
        //   const sanitizedValue = sanitizeLoanDataForTermSheet(sheetConfig.value === "--" ? "" : sheetConfig.value) || null;
        // termSheetInfo[sheetConfig.key] = sanitizedValue
        // }
        termSheetInfo[sheetConfig.key] = sheetConfig.value
      }
    });
    const payload = {
      termSheetLoanInfo: {
        ...getLoanData(termSheetInfo)
      },
      feeDetails: tsData?.feeDetails,
      requirements: tsData?.requirements || [""],
      email: {
        from: termSheetConfig?.email?.fromEmail,
        to: tsData?.emails?.toEmails?.length ? tsData?.emails?.toEmails : [],
        cc: tsData?.emails?.ccEmails?.length ? tsData?.emails?.ccEmails : [],
        subject: termSheetConfig?.email?.subject ?? "",
        content: tsData.emailBody ?? emailBodyStr2,
        date: moment(termSheetConfig?.email?.date, "MM/DD/YYYY").format("YYYY-MM-DD") ?? moment().format("YYYY-MM-DD")
      },
      emailSentOn: null
    };
    return payload
  };

  const emailBodyChange = (val: string) => {
    updateTermSheetFormData("emailBody", val);
  };

  // save termSheet data based on term sheet action 
  const getTermSheetDataBasedOnAction = async () => {
    let data: any = [];
    let newRequirements = [""];
    let newFeeDetails = {};
    let newSuggestedLoanTerms: any = [];
    let currentVersion: string | number = "";
    let toEmails: any = [];
    let ccEmails: any = [];
    let emailSentOn: any = null;
    let emailBody: any = null;
    let subject: string | null = null
    let date: string = moment(new Date()).format("MM/DD/YYYY");
    const propertyAddressDetails: any = null;

    const originatorData: any = JSON.parse(getCookie("originatorData") || "{}");
    const loanTypeStr = loanContextData?.loanDetails?.productType || loanContextData?.fetchData?.productType;

    if (termSheetAction === "generate") {
      data = getTermSheetData(loanTypeStr, loanContextData, t, termSheetAction, termSheetData, loanContextData?.defaultSelectedDataView);
      newSuggestedLoanTerms = getSuggestedLoanTerms(loanTypeStr, loanContextData, t, termSheetData);
      const feeDetailsObj: any = {};
      defaultFeeDetails?.forEach((obj: any) => {
        if (obj?.isVisible()) {
          feeDetailsObj[obj?.label] = "0.00";
        }
      });
      // DON"T REMOVE THSE COMMENTED LINES
      // if(isMultiProperty){
        // propertyAddressDetails = loanContextData?.propertyDetails?.map((prop: any, index: number) => ({
        //   label: `Property ${index+ 1}`,
        //   value: cleanedAdressString(prop.propertyLocation)
        // }))
        // data = data.map((item: any) => {
        //   return item.filter((elem: any) => elem.key !== "zipCode")
        // })
      // }
      newRequirements = [...orgLevelRequirements]
      newFeeDetails = feeDetailsObj;
      emailBody = emailBodyStr2;
      currentVersion = `${(+latestTermSheetVersion || 0) + 1}`;
      
    } else if (["regenerate", "view", "download", "edit"].includes(termSheetAction)) {
      if (currentTermSheetVersion) {
        const response: any = await fetchGetTermSheetBasedOnVersion(currentTermSheetVersion);
        if (response) {
          data = getTermSheetData(loanContextData?.loanDetails?.productType, loanContextData, t, termSheetAction, response, loanContextData?.defaultSelectedDataView);
          newSuggestedLoanTerms = getSuggestedLoanTerms(loanTypeStr, loanContextData, t, termSheetAction, response);
          newRequirements = response?.requirements || [""];
          newFeeDetails = response?.feeDetails || (JSON.parse(JSON.stringify(termSheetData?.feeDetails)));
          ccEmails = response?.email?.cc;
          toEmails = response?.email?.to;
          emailSentOn = response?.emailSentOn;
          emailBody = response?.email?.content;
          subject = response?.email?.subject;
          currentVersion = response?.termSheetVersion;
          // propertyAddressDetails = response?.propertyAddressDetails;
          date = response?.email?.date ? moment(new Date(response?.email?.date)).format("MM/DD/YYYY") :
           response.updatedAt ? moment(new Date(response.updatedAt)).format("MM/DD/YYYY") : ""
          setSignatureData(response?.signatureInfo ? response?.signatureInfo : { name: decodedData?.name, email: decodedData?.email, accountName: originatorData?.accountName });
        } else {
          data = getTermSheetData(loanTypeStr, loanContextData, t, termSheetAction, termSheetData, loanContextData?.defaultSelectedDataView);
          newSuggestedLoanTerms = getSuggestedLoanTerms(loanTypeStr, loanContextData, t, termSheetAction, termSheetData);
          newRequirements = termSheetData?.requirements || [""];
          newFeeDetails = termSheetData?.feeDetails || (JSON.parse(JSON.stringify(termSheetData?.feeDetails)));
          ccEmails = termSheetData?.email?.cc;
          toEmails = termSheetData?.email?.to;
          emailSentOn = termSheetData?.emailSentOn;
          emailBody = termSheetData?.email?.content;
          subject = termSheetData?.email?.subject
          date= termSheetData?.date
          // propertyAddressDetails = termSheetData?.propertyAddressDetails;
        }
      }
    } else {
      // TODO: get from api and add it here
      newRequirements = [""];
      newFeeDetails = { ...(JSON.parse(JSON.stringify(termSheetData?.feeDetails ?? "{}"))) };
      newSuggestedLoanTerms = []
    }
    if (["generate", "regenerate", "edit"].includes(termSheetAction)) {
      setIsOpenTermSheet(true);
      setOpenTermSheetMenu(false);
      if (["generate", "regenerate"].includes(termSheetAction)) {
        setLatestTermSheetVersion((+latestTermSheetVersion || 0) + 1);
      } else {
        setLatestTermSheetVersion(currentTermSheetVersion);
      }
    }

    setTermSheetEmailData({ fromEmail: "noreply@bplsizer.com", toEmails, ccEmails })
    setTermSheetLoanData(data)
    setTermSheetSuggestedLoanData(newSuggestedLoanTerms)
    setTermSheetFeeData(newFeeDetails)
    setTermSheetRequirementsData(newRequirements || [""])
    setTermSheetData({
      emailSentOn,
      loanData: data,
      suggestedLoanTerms: newSuggestedLoanTerms,
      requirements: newRequirements || [""],
      feeDetails: newFeeDetails,
      emailBody: emailBody ?? emailBodyStr2,
      emails: {
        fromEmail: "noreply@bplsizer.com",
        toEmails,
        ccEmails,
        subject
      },
      date,
      currentTermSheetVersion: currentVersion,
      propertyAddressDetails,
      evaluationID
    });
  };

  // Format required Adress String
  const cleanedAdressString = (propertyLocation: any) => {
    if (!propertyLocation) {
      return ""
    }
    const pattern = new RegExp(`\\b(${propertyLocation.postalCode}|${propertyLocation.city}|${propertyLocation.state})\\b`, 'g');
    // Remove matches from the address
    const cleanedAddress = propertyLocation.address.replace(pattern, '').trim().replace(/\s+/g, ' ');
    const address = propertyLocation?.address ? `${cleanedAddress}, ${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}` : ""
    return address
  }

  const handleSubjectChange = (subjectValue: any, tempTermSheetData: any) => {
    const emails = {
      ...tempTermSheetData.emails,
      subject: subjectValue
    }
    updateTermSheetFormData("emails", emails);
  }

  const defaultSubjectCheck = () => {
    if(isMultiProperty) {
      return "Loan Proposal for Multiple Properties"
    }
    const address = cleanedAdressString(loanContextData?.propertyDetails[0]?.propertyLocation);
    return `Loan Proposal for ${address}`

  }

  // set termsheet config
  useEffect(() => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    
    const originatorAccountMap: any = JSON.parse(getCookie("originatorAccountMap") || "{}");
    const originatorData: any = JSON.parse(getCookie("originatorData") || "{}");
    const defaultSubject = defaultSubjectCheck()

    const versionToBeDisplayed = "regenerate" == termSheetAction || "generate" == termSheetAction  ? latestTermSheetVersion : currentTermSheetVersion
    const baseData = {
      isViewMode: !["regenerate", "generate", "edit"].includes(termSheetAction),
      termSheetAction,
      originatorLogo: originatorAccountMap?.originatorLogo,
      tinyMceKey: dynamicConfig?.ViteAppTinyMceKey,
      header: {
        showOriginatorInfo: true,
        originatorInfo: `${originatorData?.accountName} - ID: ${evaluationID} (${t("termSheetKeyForHeader")} v ${versionToBeDisplayed ?? ""})`,
        className: classes?.termSheetHeader,
        closeIcon: CloseIcon,
      },
      email: {
        subject: defaultSubject,
        date,
        fromEmail: 'noreply@bplsizer.com',
        handleEmailChange,
        handleSubjectChange,
      },
      feeDetails: {
        // config: defaultFeeDetails,
        config: orgLevelFees?.length > 0 ? orgLevelFees : defaultFeeDetails,
        orgLevelFees,
        handleFeeChange,
        handleFeeBlur,
        handleDeleteFee,
        handleAddNewFeeConfig,
      },
      footer: {
        onCloseModal: setIsOpenTermSheet,
        onSendEmail: sendEmail,
        onSaveTermSheet: saveTermSheetHandler,
        name: decodedData?.name,
        handleFooterChange,
        isFooterEditEnabled,
        setIsFooterEditEnabled,
        EditIcon,
        // DefaultSignature,
        handleDownloadPDF
      },
      termSheetVersion: currentTermSheetVersion,
      showWaterMark: currentTermSheetVersion !== latestTermSheetVersion,
      updateTermSheetFormData,
      isEditsEnable,
      signatureInfo: signatureData,
      handleSignatureSave,
      capitalizeFirstLetterOfEachWord
    };

    const newData = { ...baseData };

    switch (termSheetAction) {
      case "view":
        setIsOpenTermSheet(true);
        setOpenTermSheetMenu(false);
        break;
      case "download":
        setIsOpenDownloadTermSheet(true);
        setOpenTermSheetMenu(false);
        break;
      default:
        break;
    }

    setTermSheetConfig(newData);
  }, [evaluationID, t, defaultFeeDetails, termSheetAction, currentTermSheetVersion, signatureData, isEditsEnable]);

  useEffect(() => {
    if (termSheetAction === "generate") {
      const originatorData: any = JSON.parse(getCookie("originatorData") || "{}");
      setSignatureData({ name: decodedData?.name, email: decodedData?.email, accountName: originatorData?.accountName });
      if(isTermsheetAlreadySaved) {
        setIsTermsheetAlreadySaved(false)
      }
    }
    }, [termSheetAction]);

  const setFeeDetailsConfig = async () => {
    const headers = {
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };
    try {
      const orgId = getCookie("orgId");
      const { data } = await getFieldConfigBasedOnOriginator(orgId, headers);
      const defaultRequirements = data?.requirement?.map((req: any) => req?.data )
      if(defaultRequirements && defaultRequirements?.length > 0) {
        setOrgLevelRequirements([...defaultRequirements])
      }
      const config: any = normalizeFeeDetailsConfig(data, loanType);
      setOrgLevelFees(config);
      if (data && termSheetAction === "regenerate") {
        setDefaultFeeDetails(config);
      } else {
        switch (loanType) {
          case "InvestorDSCR":
            setDefaultFeeDetails(DSCRDefaultFeeDetailsDefault);
            break;
          case "BridgeLoan":
            setDefaultFeeDetails(BridgeFeeDetails)
            break;
          default:
            setDefaultFeeDetails(DSCRDefaultFeeDetailsDefault);
            break;
        }
      }
    } catch (err) {
      console.error(err);
      switch (loanType) {
        case "InvestorDSCR":
          setDefaultFeeDetails(DSCRDefaultFeeDetailsDefault);
          break;
        case "BridgeLoan":
          setDefaultFeeDetails(BridgeFeeDetails)
          break;
        default:
          setDefaultFeeDetails(DSCRDefaultFeeDetailsDefault);
          break;
      }
    }
  };

  useEffect(() => {
      setFeeDetailsConfig();
    const enabledPermission = ["generate", "regenerate", "edit"]?.includes(termSheetAction) ? true : false
    setIsEditsEnable(previous => ({ ...previous, isSubjectEditable: enabledPermission, isFooterEditable: enabledPermission }));
  }, [DSCRDefaultFeeDetailsDefault, BridgeFeeDetails, termSheetAction]);

  // get term sheet data based on term sheet action
  useEffect(() => {
    if ((openTermSheetMenu || termSheetAction === 'regenerate') && termSheetAction && showTermSheet) {
      getTermSheetDataBasedOnAction();
    }
  }, [termSheetAction, openTermSheetMenu, showTermSheet]);

  // get term sheet versions on load
  useEffect(() => {
    loanId && getTermSheetVersion();
  }, []);

  useEffect(() => {
    setTriggeredSendEmail(false);
  }, [termSheetData?.emails?.toEmails]);

  // get term sheet data based on version
  const fetchGetTermSheetBasedOnVersion = async (termSheetVersion = currentTermSheetVersion) => {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const response = await getTermSheetBasedOnVersion(realmHeaders, loanId, +termSheetVersion);
      return response?.data?.data
    } catch (err) {
      console.error(err);
    }
  }

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMsg("");
  };

  const regenerateTermSheet = async (loanData: any) => {
    const realmHeaders = {
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    }
    const termSheetInfo: any = {};
    if (termSheetData && termSheetAction === "regenerate") {
      const termSheetLoanDataConfig = [
        ...loanData[0],
        ...loanData[1]
      ];

      termSheetLoanDataConfig?.map((sheetConfig) => {
        if (sheetConfig.key !== "--") {
          termSheetInfo[sheetConfig.key] = sheetConfig.value
        } else if (sheetConfig.value == "--") {
          termSheetInfo[sheetConfig.key] = null;
        }
      });
    }
    if (["regenerate"].includes(termSheetAction)) {
      const payload = {
        termSheetLoanInfo: {
          ...termSheetInfo
        },
        feeDetails: termSheetData?.feeDetails,
        requirements: termSheetData?.requirements || [""],
        emailSentOn: termSheetData?.emailSentOn,
        email: {
          from: termSheetData?.email?.from,
          to: termSheetData?.email?.to,
          cc: termSheetData?.email?.cc,
          subject: termSheetData?.email?.subject,
          content: termSheetData?.email?.content,
          date: moment().format("MM/DD/YYYY")
        }
      };
      const newTermSheetResponse: any = await generateNewTermSheet(payload, loanId, realmHeaders);
      const newData = {
        loanId: loanId,
        termSheetVersion: newTermSheetResponse?.data?.data?.termSheetVersion,
        createdAt: newTermSheetResponse?.data?.data?.createdAt,
        createdBy: newTermSheetResponse?.data?.data?.createdBy,
      }
      setTermSheetVersions((previous: any) => [newData, ...previous]);
      setIsOpenTermSheet(true);
      setOpenTermSheetMenu(false);
      setEmailContent(termSheetData?.email?.content);
    }
  };

  const handleDownloadPDF = () => {
    if(!isTermsheetAlreadySaved) {
      setShowDisclaimer(true)
    } else {
      setIsOpenDownloadTermSheet(true)
    }
  };

  const containedButtonHandler = async () => {
    if (termSheetData?.emails?.toEmails?.length === 0) {
      setTriggeredSendEmail(true);
      setShowDisclaimer(false)
      return;
    }
    const mode =  isTermsheetAlreadySaved ? "update" :'create'
    const isEmailSent = false
    await saveOrUpdateNewTermSheet(termSheetData, mode, isEmailSent)
    setIsOpenDownloadTermSheet(true)
    setShowDisclaimer(false)
  }

  const outlinedButtonHandler = () => {
    setIsOpenDownloadTermSheet(true)
    setShowDisclaimer(false)
  }

  const closeDownloadDisclaimer = () => {
    setShowDisclaimer(false)
  }

  return (
    <div>
      <Grid item className="gray-border">
        <Button
          className="mr-12"
          variant="outlined"
          onClick={handleClick}
          data-testid="create-term-sheet-button"
          endIcon={<KeyboardArrowDownIcon />}
        >
          {t("termSheet")}
        </Button>
        {openTermSheetMenu ?
          <TermSheetMenu
            openTermSheetMenu={openTermSheetMenu}
            termSheetAnchorEl={termSheetAnchorEl}
            setIsOpenTermSheet={setIsOpenTermSheet}
            termSheetConfig={termSheetConfig}
            handleClose={handleClose}
            setTermSheetAction={setTermSheetAction}
            termSheetVersionsData={termSheetVersions}
            setCurrentTermSheetVersion={setCurrentTermSheetVersion}
            isEmailSentOfCurrentVersion={isEmailSentOfCurrentVersion}
          /> : null}
      </Grid>
      {
        isOpenTermSheet ?
          <TermSheetV2
            isOpenTermSheet={isOpenTermSheet}
            termSheetData={termSheetData}
            setTermSheetData={setTermSheetData}
            isUserConfig={false}
            permissionsToRenderInUI={permissionsToRenderInUI}
            // setIsOpenTermSheet={setIsOpenTermSheet}
            onClose={handleClose}
            termSheetConfig={termSheetConfig}
            // termSheetAction={termSheetAction}
            triggeredSendEmail={triggeredSendEmail}
            emailBodyChange={emailBodyChange}
            sanitizeCurrency={sanitizeCurrency}
            isTermsheetAlreadySaved={isTermsheetAlreadySaved}
          />
          : null
      }
      {
        isOpenDownloadTermSheet ?
          <DownloadTermSheet
            termSheetData={termSheetData}
            setTermSheetData={setTermSheetData}
            isUserConfig={false}
            termSheetConfig={termSheetConfig}
            permissionsToRenderInUI={permissionsToRenderInUI}
            // showEmailSection={true}
            termSheetAction={termSheetAction}
            setIsOpenDownloadTermSheet={setIsOpenDownloadTermSheet}
            isOpenDownloadTermSheet={isOpenDownloadTermSheet}
            sanitizeCurrency={sanitizeCurrency}
          />
          : null
      }
      {snackBarMsg && <SnackBar message={snackBarMsg} handleClose={handleCloseSnackBar} />}
      {showDisclaimer && 
      <DisclaimerPopupWithButtons 
        openDisclaimer={showDisclaimer} 
        disclaimerHeading={'Disclaimer'}
        disclaimerText={`You haven't saved your Termsheet yet. Would you like to save it before downloading?`}
        containedButtonLabel={`Save & Download`}
        containedButtonHandler={containedButtonHandler}
        outlinedButtonLabel={`Download Only`}
        outlinedButtonHandler={outlinedButtonHandler}
        closeDisclaimer={closeDownloadDisclaimer}
      />}

    </div>
  )
}
